/* eslint-disable */
import { useLocation, useRoutes } from 'react-router-dom'
import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import ResetPassword from './routes/reset-password'
import Verification from './routes/verification'
import EmailAcitvation from './routes/email-activation'
import Home from './routes/home'

const Main = styled.div`
  min-width: 90vw;
  min-height: 100vh;
  background-color: #ebf7f7;
`

function App() {
  const [user, setUser] = useState(localStorage.getItem('user'))
  const location = useLocation()
  const navigate = useNavigate()

  const authRoutes = [
    '/user/password-reset/:uid/:token',
    '/verification',
    '/user/activate/:uid/:token',
  ]

  useEffect(() => {
    const storedUser = localStorage.getItem('user')
    setUser(storedUser)


    if (storedUser && authRoutes.includes(location.pathname)) {
      navigate('/')
    }
  }, [location])

  const routes = [
    {
      path: '/user/password-reset/:uid/:token',
      caseSensitive: true,
      element: <ResetPassword />,
    },
    {
      path: '/verification',
      caseSensitive: true,
      element: <Verification />,
    },
    {
      path: '/',
      caseSensitive: true,
      element:  <Home />,
    },
    {
      path: '/user/activate/:uid/:token',
      caseSensitive: true,
      element: <EmailAcitvation />,
    },
  ]

  const route = useRoutes(routes)

  return <Main>{route}</Main>
}

export default App
