
import styled from 'styled-components'
import { isDesktop } from 'react-device-detect'

const Main = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
`

const BackgroundSection = styled.div`
  background-size: cover;
  background-position: 0px 0px;
`



const Subheading = styled.span`
  font-size: ${isDesktop ? '35px' : '30px'};
  font-weight: bold;
  align-self: center;
`


const Home = () => {

  return (
    <Main>

      <BackgroundSection>


      </BackgroundSection>



      <Subheading>NextBreath APP</Subheading>

    </Main>
  )
}

export default Home
