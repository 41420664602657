/* eslint-disable */
import { Button, InputAdornment, InputBase } from '@mui/material'
import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import VisibilityIcon from '@mui/icons-material/Visibility'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import Logo from '../../assets/nextbreath.png'
import PasswordChanged from '../../components/PasswordChanged'
import ConfirmationIcon from '../../assets/ConfirmationIcon.png'
import { isDesktop } from 'react-device-detect'
import { useParams } from 'react-router-dom'
import { resetPassword } from '../../api-handlers/auth'
import CheckRoundedIcon from '@mui/icons-material/CheckRounded'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'

const Main = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-size: contain;
  min-height: 100vh;
`

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: ${isDesktop ? '40%' : '80%'};
`

const Heading = styled.span`
  font-size: ${isDesktop ? '45px' : '30px'};
  font-weight: bold;
  margin-bottom: 1rem;
`

const Input = styled(InputBase)`
  border: 1px solid #6d6d6d;
  border-radius: 4px;
  padding: 6px 20px;
`

const ErrorText = styled.span`
  font-size: 16px;
  color: red;
  font-weight: 500;
`

const ResetPassword = () => {
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [showPassword, setShowPassword] = useState(false)
  const [showConfirmPassword, setShowConfirmPassword] = useState(false)
  const [showPasswordChanged, setShowPasswordChanged] = useState(false)
  const [isPasswordValid, setIsPasswordValid] = useState(false)
  const [isPasswordTouched, setIsPasswordTouched] = useState(false)
  const [validationJSX, setValidationJSX] = useState(null)
  const [error, setError] = useState('')

  const { uid, token } = useParams()

  useEffect(() => {
    if (isPasswordTouched) {
      const { jsx, allValid } = validatePassword(password)
      setValidationJSX(jsx)
      setIsPasswordValid(allValid)
    }
  }, [password, isPasswordTouched])

  const validatePassword = password => {
    const checks = {
      length: password.length >= 6 && password.length < 99,
      specialChar: /[=+\-^$*{}\[\]()`?!@#%&/\\><]/.test(password),
      uppercase: /[A-Z]/.test(password),
      lowercase: /[a-z]/.test(password),
    }

    const allValid = Object.values(checks).every(Boolean)

    const jsx = (
      <div
        style={{
          backgroundColor: '#f0f8f8',
          padding: '10px',
          borderRadius: '5px',
        }}
      >
        <div style={{ display: 'flex' }}>
          {checks.length ? (
            <CheckRoundedIcon style={{ color: 'green' }} />
          ) : (
            <CloseRoundedIcon style={{ color: 'red' }} />
          )}
          <span
            style={{
              marginLeft: '5px',
              color: checks.length ? 'green' : 'red',
            }}
          >
            Minimum length, which must be at least 6 characters but fewer than
            99 characters
          </span>
        </div>
        <div style={{ display: 'flex' }}>
          {checks.specialChar ? (
            <CheckRoundedIcon style={{ color: 'green' }} />
          ) : (
            <CloseRoundedIcon style={{ color: 'red' }} />
          )}
          <span
            style={{
              marginLeft: '5px',
              color: checks.specialChar ? 'green' : 'red',
            }}
          >
            {
              'Require a special character: = + - ^ $ * [] {} () ? ! @ # % & /  >'
            }
          </span>
        </div>
        <div style={{ display: 'flex' }}>
          {checks.uppercase ? (
            <CheckRoundedIcon style={{ color: 'green' }} />
          ) : (
            <CloseRoundedIcon style={{ color: 'red' }} />
          )}
          <span
            style={{
              marginLeft: '5px',
              color: checks.uppercase ? 'green' : 'red',
            }}
          >
            Require uppercase letters
          </span>
        </div>
        <div style={{ display: 'flex' }}>
          {checks.lowercase ? (
            <CheckRoundedIcon style={{ color: 'green' }} />
          ) : (
            <CloseRoundedIcon style={{ color: 'red' }} />
          )}
          <span
            style={{
              marginLeft: '5px',
              color: checks.lowercase ? 'green' : 'red',
            }}
          >
            Require lowercase letters
          </span>
        </div>
      </div>
    )

    return { jsx, allValid }
  }

  const handleContinueClick = async () => {
    if (password !== confirmPassword) {
      setError('Passwords do not match')
      return
    }
    const body = {
      uid: uid,
      token: token,
      new_password1: password,
      new_password2: confirmPassword,
    }
    try {
      const response = await resetPassword(body)
      if (response.status === 202) {
        setShowPasswordChanged(true)
      } else {
        setError(response.data.error)
      }
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <div>
      {!showPasswordChanged ? (
        <Main>
          <img
            style={{
              margin: '3rem 0',
              height: isDesktop ? '80px' : '50px',  // Adjust the height as needed
              width: isDesktop ? '300px' : '190px',  // Adjust the width as needed
            }}
            src={Logo}
            alt='Logo'
          />
          <FormContainer>
            <Heading>Reset Password</Heading>
            <Input
              type={showPassword ? '' : 'password'}
              style={{ margin: '0.5rem 0' }}
              placeholder='Password'
              value={password}
              endAdornment={
                <InputAdornment position='end'>
                  {showPassword ? (
                    <VisibilityOffIcon
                      style={{ cursor: 'pointer' }}
                      onClick={() => setShowPassword(false)}
                    />
                  ) : (
                    <VisibilityIcon
                      style={{ cursor: 'pointer' }}
                      onClick={() => setShowPassword(true)}
                    />
                  )}
                </InputAdornment>
              }
              onChange={e => setPassword(e.target.value)}
              onFocus={() => setIsPasswordTouched(true)}
            />
             {isPasswordTouched && (
              <div style={{ marginTop: '0.5rem' }}>{validationJSX}</div>
            )}
            <Input
              type={showConfirmPassword ? '' : 'password'}
              style={{ margin: '0.5rem 0' }}
              placeholder='Confirm password'
              value={confirmPassword}
              endAdornment={
                <InputAdornment position='end'>
                  {showConfirmPassword ? (
                    <VisibilityOffIcon
                      style={{ cursor: 'pointer' }}
                      onClick={() => setShowConfirmPassword(false)}
                    />
                  ) : (
                    <VisibilityIcon
                      style={{ cursor: 'pointer' }}
                      onClick={() => setShowConfirmPassword(true)}
                    />
                  )}
                </InputAdornment>
              }
              onChange={e => setConfirmPassword(e.target.value)}
            />
          </FormContainer>
          {error && <ErrorText>{error}</ErrorText>}
          <Button
            variant='contained'
            disabled={
              password.trim() === '' || confirmPassword.trim() === '' || !isPasswordValid
            }
            sx={{
              textTransform: 'none',
              backgroundColor: '#002CB6',
              width: isDesktop ? '40%' : '80%',
              color: 'white',
              marginTop: '1rem',

              '&:hover': {
                backgroundColor: '#0038E5',
              },

              '&:disabled': {
                backgroundColor: '#002CB6',
                color: 'white',
                opacity: 0.5,
              },
            }}
            onClick={handleContinueClick}
          >
            Continue
          </Button>
        </Main>
      ) : (
        <PasswordChanged logo={Logo} icon={ConfirmationIcon} />
      )}
    </div>
  )
}

export default ResetPassword
