/* eslint-disable */
import styled from 'styled-components'
import Logo from '../../assets/nextbreath.png'
import { Button } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import VerifiedEmail from '../../assets/VerifiedEmail.png'
import { isDesktop } from 'react-device-detect'
import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import DoDisturbRoundedIcon from '@mui/icons-material/DoDisturbRounded'

const Main = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-size: contain;
  min-height: 100vh;
`

const Heading = styled.span`
  font-size: ${isDesktop ? '45px' : '30px'};
  font-weight: bold;
  margin-bottom: 1rem;
`

const Text = styled.span`
  font-size: 16px;
  color: #6d6d6d;
  margin: ${!isDesktop && '0 2rem'};
  text-align: center;
  margin-top: ${!isDesktop && '1rem'};
`

const Verification = () => {
  const [error, setError] = useState(null)

  const navigate = useNavigate()
  const location = useLocation()

  useEffect(() => {
    if (location.state) {
      setError(location.state.error)
    }
  }, [])

  return (
    <Main>
      <img
        style={{
          margin: '3rem 0',
          height: isDesktop ? '80px' : '50px',  // Adjust the height as needed
          width: isDesktop ? '300px' : '190px',  // Adjust the width as needed
        }}
        src={Logo}
        alt='Logo'
      />
      {error ? (
        <Heading>Email Verification Invalid</Heading>
      ) : (
        <Heading>Email Verified</Heading>
      )}
      {error ? (
        <DoDisturbRoundedIcon sx={{ fontSize: '250px', color: 'red' }} />
      ) : (
        <img
          style={{
            height: !isDesktop && '100px',
            width: !isDesktop && '130px',
            marginTop: !isDesktop && '1rem',
          }}
          src={VerifiedEmail}
          alt='Verified Email'
        />
      )}
      <Text>
        {error
          ? error
          : ' Your email has been successfully verified. Click below to log in.'}
      </Text>
      <Button
        variant='contained'
        sx={{
          textTransform: 'none',
          backgroundColor: '##002CB6',
          width: isDesktop ? '40%' : '80%',
          color: 'white',
          marginTop: isDesktop ? '1rem' : '2rem',

          '&:hover': {
            backgroundColor: '##0038E5',
          },
        }}
        onClick={() => window.location.href = 'https://www.nextbreathapp.com/'}
      >
        Continue
      </Button>
    </Main>
  )
}

export default Verification
