import axios from 'axios'

const url = `${process.env.REACT_APP_BASE_URL}`


export const activateEmail = async body => {
  try {
    const response = await axios.post(url + '/api/v1/user/activate/', body, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    return response
  } catch (error) {
    console.error(error)
  }
}



export const resetPassword = async body => {
  try {
    const response = await axios.post(
      url + '/api/v1/user/password-reset/confirm/',
      body,
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    )
    return response
  } catch (error) {
    console.error(error)
  }
}


